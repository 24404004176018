import { template as template_00c1b9eae6464c4a89bd37e0ebf21d99 } from "@ember/template-compiler";
const FKText = template_00c1b9eae6464c4a89bd37e0ebf21d99(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
