import { template as template_2c738db894684d38b6a3a8360efd084e } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_2c738db894684d38b6a3a8360efd084e(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
