import { template as template_bb2c8e1d30e04d7f90c3093526c5e864 } from "@ember/template-compiler";
import icon from "discourse-common/helpers/d-icon";
const InputTip = template_bb2c8e1d30e04d7f90c3093526c5e864(`
  <div
    class="tip {{if @validation.failed 'bad' 'good'}}"
    id={{@id}}
    ...attributes
  >
    {{#if @validation.reason}}
      {{icon (if @validation.failed "xmark" "check")}}
      {{@validation.reason}}
    {{/if}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default InputTip;
