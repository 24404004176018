import { template as template_b175bbb427d64cedb7ec333175cc2bfd } from "@ember/template-compiler";
const FKControlMenuContainer = template_b175bbb427d64cedb7ec333175cc2bfd(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
