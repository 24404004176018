import { template as template_ec4da1f21740412ebc52fd54a5e05471 } from "@ember/template-compiler";
const FKLabel = template_ec4da1f21740412ebc52fd54a5e05471(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
